<template>
  <div>
    <h4 class="mb-4 page-title">Whatsapp рассылка</h4>
    <div class='filter__container'>
      <div class='row mb-3 justify-content-between'>
        <div class='col-4 text-right'>
          <button class="button_add btn__add" @click="addData">Новая рассылка</button>
        </div>
      </div>
      <div class='input-group col-4'>

      </div>
    </div>
    <div class="widget">
      <div class="widget__toolbar">
        <div class="d-flex justify-content-between">
          <h3 v-if="messages && messages.length === 0">Нет рассылок, но вы можете создать новую</h3>
        </div>
      </div>
      <div class="table_wrapper" v-if="messages && messages.length > 0">
        <table class="table table-hover">
          <thead class="table_head">
          <tr>
            <th>Название</th>
            <th>Сообщение</th>
            <th>Кому</th>
            <th>Отправитель</th>
            <th class="sent">Отправлено</th>
            <th class="sent">Статус</th>
            <th class="text-center actions">Действия</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="message in messages" :key="message.id">
            <td>{{ message.name }}</td>
            <td>{{ message.message }}</td>
            <td>{{ message.send_type }}</td>
            <td>{{ message.sender }}</td>
            <td class="text-center">{{ message.count }}</td>
            <td class="text-center">{{ message.status }}</td>
            <td class="actions_container">
              <a class="btn mr-2 btn-primary" title="запустить"
                 @click.prevent="repeatSend(message.id, message.name)">
                <span class="fa fa-play-circle"></span>
              </a>
              <router-link class="leads_table_action_link btn mr-2 btn-primary"
                           :to="{name: 'SmsSendDetail', params: {id: message.id}}">
                <span class="glyphicon glyphicon-edit"/>
              </router-link>
              <a class="btn mr-2 btn-danger" @click.prevent="deleteRow(message.id, message.name)">
                <span class="fa fa-trash-o delete"></span>
              </a>

            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import DeleteModal from "../../components/Modal/DeleteModal";

export default {
  name: "SmsSend",
  data() {
    return {
      messages: null,
      text: "Уважаемый {{ parrent_name }} мы переехали"
    }
  },
  methods: {
    async repeatSend(id, name) {
      if (id, name) {
          this.$modal.show(
              DeleteModal,
              {
                  recordId: id,
                  recordName: name,
                  title: "Вы хотите запустить рассылку?",
                  deleteHandler: async() => {
                      try {
                          const {data} = await this.$axios.post('/api/wazzup-mailing/start', {
                              id: id
                          });
                          if (data && data.success) {
                              this.notify('Успешно', 'success')
                          }
                          if (data && data.err) {
                              this.notify(data.message, 'error')
                          }
                          console.log(data);
                      } catch (e) {
                          console.log(e.response)
                          this.$toasted.error(e.message)
                      }
                  }
              }, {name: 'DeleteModal'}
          )
      }
    },
    addData(e) {
      e.preventDefault()
      this.$router.push({name: 'SmsSendAdd'})
    },
    async fetchData() {
      try {
        const {data} = await this.$axios.get('api/mailing/wa')
        this.messages = data;
        console.log(data)
      } catch (e) {
        console.error(e)
      }
    },

    notify(msg, type) {
      this.$toasted[type](msg, {
        position: 'bottom-right',
        action: {
          text: 'Закрыть',
          onClick: (e, toastObject) => {
            toastObject.goAway(0);
          }
        }
      })
    },

    deleteRow(id, name) {
      this.$modal.show(
          DeleteModal,
          {
            recordId: id,
            recordName: name,
            deleteHandler: this.deleteRecord
          }, {name: 'DeleteModal'}
      )
    },
    async deleteRecord(id) {
      try {
        console.log(id);
        const {data} = await this.$axios.delete(`/api/mailing/wa/${id}`)
        if (data && data.status === 'deleted') {
          this.notify('Удалено!', 'success')
          this.fetchData();
        }
      } catch (e) {
        this.notify('Ошибка', 'error')
        console.log(e)
      }
    },
  },
  async created() {
    await this.fetchData();
  }
}
</script>

<style lang="scss" scoped>
.sent {
  width: 150px;
  text-align: center;
}

.widget__toolbar {
  padding: 5px 0px;
}

.btn__add {
  float: right;
}

.actions_container {
  width: 200px;
  display: block;
  text-align: center;
}

.actions {
  width: 150px;
}
</style>